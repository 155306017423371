import React, { Component } from 'react';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import _ from 'lodash';
import { RadioButton } from '../../../kado/components';
import { getTrans } from '../../../kado/utils';
import AssActions from '../../../actions/assessments';
import I18, { i18Get } from '../../../i18';
//import { ConfirmBox } from '../../../kado/utils/confirm-box';
import { Radio } from 'antd';
import './ass-components.css';
import RadioButtonLikert from './radio-button-likert';
import ConfirmBox from './confirm-box/confirm-box';


const RadioGroup = Radio.Group;
class LikertOption extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.ansChange = this.ansChange.bind(this);
    }
    renderOptions(items,q,index) { 
        const radioStyle = {
            //   display: 'block',
            //   height: '30px',
            //   lineHeight: '30px',
            };
        let isEQ_5D_5L= q.ansType === 'f' && q.slider_type_question === 's' 
        return (
                <tr >
                    <td scope="col" style={{fontSize: '1em'}} className='d-flex'>
                        {!isEQ_5D_5L && q.questionnaire && q.questionnaire.show_question_order && <label className="mr-1 qs-name mb-0">{q.assessment_type !== 'q' ? index + 1 : q.order}.</label>}
                        {!q.questionnaire && <label className='qs-name mb-0'>{q.assessment_type !== 'q' ? index + 1 : q.order}.</label>}
                        <span className='qs-name'>{!(isEQ_5D_5L) && getTrans(q, 'title') && renderHTML(getTrans(q, 'title'))}</span>
                    </td>
                    {items.map(item => {
                        return (
                            <td scope='col'  key={item.value} style={{textAlign:'center'}} onClick={()=>this.ansChange(item.value)}>
                            <RadioButtonLikert
                                key={item.uuid}
                                items={item}
                                isQuestionire={true}
                                // onClick={(e)=>this.isUnCheckAnswer(e,this.question.answer.option)}
                                // onChange={this.ansChange}
                                value={this.question.answer.option || null}
                                color="green"
                                horizontal={this.question.option_style === 's'}
                                block
                            />
                            </td>
                        ) 
                    })
                    }
                </tr>
            )
        }
    ansChange(v) {
        this.isUnCheckAnswer(v,this.question.answer.option)
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: v,
        }));
        let ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    isUnCheckAnswer(e,val){
        if(e=== val){
            ConfirmBox('', i18Get('Are you sure you want to clear the selected answer?'), ()=>{this.unCheckAnswer()})
        }
    }
    unCheckAnswer(e){
        
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: null,
        }));
        let ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
        this.state.selectedAnswer= "notspecified"; 
    }
    
    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question.uuid });
        // console.log('this.question',this.question)
        const items = this.question.options.map(x => ({
            value: x.uuid,
            title: renderHTML(getTrans(x, 'title')),
        }));
        let rightAligned = this.question.option_style !== 's' && this.question.right_alignment;
        let checkbox = this.question.option_style !== 's' && this.question.check_box;
        return (
                this.renderOptions(items,this.question,this.props.index)
               
        );
    }
    
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(LikertOption);