import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import _ from 'lodash';
import I18, { i18Get } from '../../../i18';

const RadioGroup = Radio.Group;
class RadioButtonLikert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            value: this.getValue(props),
            color: this.props.color,
            disabled: this.props.disabled,
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps,
            value: this.getValue(nextProps),
        });
    }
    getValue(props) {
        let value = null;
        if (!_.isUndefined(props.value)) {
            value = props.value;
        } else if (_.isArray(props.items) && props.items.length) {
            value = props.items[0].value || props.items[0].uuid || null;
        }
        return value;
    }
    onRadioClick(v){
        if(this.props.onClick){
            this.props.onClick(v)
        }
    }
    renderOptionsLikert() {
        var minerva_imp = false
        var x = this.props.items
            if(x.is_primary === true){
                minerva_imp =true
            }
        
            const block = this.props.horizontal ? '' : 'radio-block';
            const color = x.color ? `${x.color}-inverted` : '';
            if (minerva_imp === true)
                return (
                    <Radio key={x.value || x.uuid} onClick={()=>this.onRadioClick(x.value)}  disabled={this.state.disabled} className={`${this.props.isQuestionire? "d-flex":x.is_primary!== 'undefined' && x.is_primary ?this.props.className:''} ${this.state.value === x.value ? x.is_primary!== 'undefined' && !x.is_primary?'selected-option-grey':'selected-option':''}  ${block} ${color}`} value={x.value || x.uuid}></Radio>
            );
            else
                return (
                <Radio key={x.value || x.uuid} onClick={()=>this.onRadioClick(x.value)}  disabled={this.state.disabled} className={`${this.props.isQuestionire? "d-flex":""} ${this.state.value === x.value ? 'selected-option':''}  ${block} ${color}`} value={x.value || x.uuid}></Radio>
            )
        
    }
    render() {
        let defval = this.props.defval || 0;
        const inverted = this.props.inverted ? 'inverted' : '';
        const className = `kadoRadio  ${this.state.color} ${inverted} ${this.props.horizontal}`;
        return (
            <RadioGroup className={className} value={this.state.value} onChange={this.onChange}>
                {this.renderOptionsLikert()}
            </RadioGroup>
        );
    }
}
export default connect(state => ({
    translations: state.translations,
}))(RadioButtonLikert);
