import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Menu, Dropdown } from 'antd';
import _ from 'lodash';
import renderHTML from 'react-render-html';
import NumberFormat from 'react-number-format';
import Slider from 'react-rangeslider';
import './ass-components.css';
import AssActions from '../../../actions/assessments';
import { getTrans } from '../../../kado/utils';
import I18, { i18Get } from '../../../i18';
import { OptionsIcon, Close, AddIcon, Minus} from '../../../kado/images'

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            labels: {},
            visible: false,
        };
        this.ansChange = this.ansChange.bind(this);
        this.sliderChange = this.sliderChange.bind(this);
        this.question = null;
    }
    onChangeComplete = () => {
        const ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }

    ansChange({ value }) {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: value,
        }));

        const ans = _.find(this.props.assessments.selectedAssQA, { uuid: this.question.uuid });
        this.props.dispatch(AssActions.ass.documentQuestion(_.find(this.props.assessments.assList, 'selected').uuid, [ans.answer]));
    }
    addSliderArrow() {
        setTimeout(function () {
            const div = document.createElement('div');
            div.className = 'slider-arrow';
            const arrowHandle = document.getElementsByClassName('rangeslider__handle')
            if (arrowHandle.length) {
                for (var i = 0; i < arrowHandle.length; i++) {
                    arrowHandle[i].appendChild(div)
                }
            }
        }, 500);
    }
    openSliderModal = () => {
        this.setState({
            visible: true
        }, () => {
            this.addSliderArrow()
        })
    }
    modalFooter = () => {
        return (
                <button className="btn-with-brand button kado-btn" onClick={this.onSave} style={{ marginRight: 8 }}>
                    {i18Get('Save')}
                </button>
        )
    }
    onClose = () => {
        this.setState({
            visible: false
        })
    }
    onSave = () => {
        if(this.state.value === null || typeof(this.state.value) === 'undefined'){
            this.sliderChange(this.state.rangeFrom);
            this.onChangeComplete();
        }
        this.setState({
            visible: false
        })
    }
    restValue = () => {
        this.sliderChange(null);
        this.onChangeComplete();
    }
    renderTilte = (q) => {
        const menu = (
            <Menu className="user-options">
                <Menu.Item key="1">
                    <div onClick={this.restValue} >{i18Get('Reset')} </div>
                </Menu.Item>
            </Menu>
        )
        return (
            <div className="d-flex w-100 align-items-center justify-content-between quest-sliderheader">
                <span>
                    {/* {(getTrans(q, "title"))} */}
                </span>
                <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
                    <button className="icon-btn drawer-heading-btn">
                        <OptionsIcon />
                    </button>
                </Dropdown>
                <button onClick={this.onClose} className="icon-btn drawer-heading-btn">
                    <Close />
                </button>
            </div>
        )
    }


    // sliderChange({ value }) {
    //     this.props.dispatch(AssActions.ass.markAnswer({
    //         question: this.question.uuid,
    //         answer: value,
    //     }));
    // }
    sliderChange(value) {
        this.setState({
            value: value
        })
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer: value,
        }));
    }
    formatSlider = (value) => {
        // commented due to slider causing error of title
        // return this.state.items[value-1].title
    }
    optionSliderChange = (value) => {
        this.props.dispatch(AssActions.ass.markAnswer({
            question: this.question.uuid,
            answer:  this.state.items[value - 1].value,
        }));
    }
    triggerPlusClick = () => {
        let currentValue = this.state.value;
        if(currentValue === 'undefined' || currentValue === null || currentValue === ''){
            currentValue = this.state.rangeFrom
        }
        let value = currentValue + this.state.stepInterval
        if (value > this.state.rangeTo) {
            value = this.state.rangeTo
        }
        this.sliderChange(value);
        this.onChangeComplete();
    }
    triggerMinusClick = () => {
        let currentValue = this.state.value;
        if(currentValue === 'undefined' || currentValue === null || currentValue === ''){
            currentValue = this.state.rangeFrom
        }
        let value = currentValue - this.state.stepInterval
        if (value < this.state.rangeFrom) {
            value = this.state.rangeFrom
        }
        this.sliderChange(value);
        this.onChangeComplete();
    }
    getSliderTitle(title) {
        const width = window.innerWidth
        return <div title={title}>{title.length > 8 ? <div style={{transform:'rotate(-90deg' , marginTop:25}}> {title}</div>: title } </div>

        // if(width < 400) {
        //     return <div title={title}>{title.length > 6 ? title.slice(0, 3) + '..' : title } </div>
        // } else if(width < 700) {
        //     return <div title={title}>{title.length > 8 ? title.slice(0, 4) + '...' : title } </div>
        // } else if(width < 1000) {
        //     return <div title={title}>{title.length > 10 ? title.slice(0, 6) + '...' : title } </div>
        // } else if(width < 1350) {
        //     return <div title={title}>{title.length > 12 ? title.slice(0, 7) + '...' : title } </div>
        // } else {
        //     return <div title={title}>{title.length > 16 ? title.slice(0, 12) + '...' : title } </div>
        // }
    }
    render() {
        const selectedAss = _.find(this.props.assessments.assList, 'selected');
        this.question = _.find(this.props.assessments.selectedAssQA, { uuid: this.props.question });
        const items = this.question.options.map(x => ({
            value: x.uuid,
            label: getTrans(x, 'title'),
        }));
        this.state.value = this.question.answer.floatAns
        if(this.question.config) {
            this.state.rangeFrom = this.question.config.rangeFrom
            this.state.rangeTo = this.question.config.rangeTo
            this.state.stepInterval = this.question.config.stepInterval
        }
        const labels = {};
        let isAnswer = this.question.answer.floatAns >= 0 && this.question.answer.floatAns !== null && typeof(this.question.answer.floatAns) !== 'undefined'
        if (this.question.config && this.question.config.rangeInput) {
            const {
                rangeFrom, rangeTo, rangeInput, stepInterval,
            } = this.question.config;
    
            const interval = (rangeTo - rangeFrom) / 10;
            for (let i = rangeFrom; i <= rangeTo; i += interval) {
                labels[Math.ceil(i)] = Math.ceil(i);
            }
            if(this.question.slider_type_question=== "v"){
                return (
                    <React.Fragment>
                        <div onClick={this.openSliderModal} className={`btn-group d-flex ${selectedAss.documented || selectedAss.status === 'a' ? 'non-pointer' : ''}`}>
                            <input
                                disabled
                                // value={this.props.answer ? this.props.answer.floatAns === null ? '' : this.props.answer.floatAns : null} 
                                value={(isAnswer)? this.question.answer.floatAns : ""}
                                placeholder={i18Get('Select the value')}
                                className="mb-0"
                                type="text"
                            />
                            <button className="kado-btn">
                                {i18Get('Edit')}
                            </button>
                        </div>
                        <Drawer
                            className="quest-slider"
                            title={this.renderTilte(this.props.question)}
                            placement="right"
                            closable={false}
                            onClose={this.onClose}
                            visible={this.state.visible}
                        >
                            <React.Fragment>
                                <div className={`drawer-body-content w-100 numeric-slider ${selectedAss.documented || selectedAss.status === 'a' ? "disable_range_slider non-pointer" : "slider-option-wrap"}`}>
                                    <div className="slider-btn-wrap">
                                        <button onClick={this.triggerPlusClick} className="icon-btn circle with-bg">
                                            <AddIcon />
                                        </button>
                                        <button onClick={this.triggerMinusClick} className="icon-btn circle with-bg">
                                            <Minus />
                                        </button>
                                    </div>
                                    <Slider className={`drawer-slider flat-slider`}
                                        ref={this.rangeRef}
                                        min={rangeFrom}
                                        max={rangeTo}
                                        step={stepInterval}
                                        value={isAnswer? this.question.answer.floatAns : rangeFrom}
                                        labels={labels}
                                        handleLabel={isAnswer? this.question.answer.floatAns : rangeFrom}
                                        onChange={this.sliderChange}
                                        orientation="vertical"
                                        onChangeComplete={this.onChangeComplete}
                                    />
                                </div>
                                <div className="drawer-footer">
                                    {this.modalFooter()}
                                </div>
                            </React.Fragment>
                        </Drawer>
                    </React.Fragment>
                );
            }
            return(
                <Slider
                    className={`${this.question.answer.floatAns  === null ? 'slider-not-selected' : ''} ${selectedAss.documented || selectedAss.status === 'a' ? " non-pointer" : ""}`}
                    ref={this.rangeRef}
                    min={rangeFrom}
                    max={rangeTo}
                    step={stepInterval}
                    value={this.question.answer.floatAns || 0}
                    labels={labels}
                    onChange={this.sliderChange}
                    onChangeComplete={this.onChangeComplete}
                />
            )
            
        } else if (this.question.ansType === 'o' && this.question.option_style === 'rs') {
            const sliderStep = 1;
            this.state.min = 1;
            this.state.max = this.question.options.length;
            this.state.items = this.question.options.map(x => ({
                value: x.uuid,
                title: getTrans(x, 'title'),
            }));
            this.state.value = 0;
            this.question.options.map((o, index) => {
                var title = getTrans(o, 'title')
                if(title) {
                    title = renderHTML(title)
                }
                this.state.labels[index + 1] = this.getSliderTitle(title);
                if (this.question.answer && this.question.answer.option === o.uuid) {
                    this.state.value = index + 1;
                }
            });
            const longest_label_length = Math.max(...this.question.options.map(a=>a.title.length));
            return (
            <Slider
                className={`quest-slider ${longest_label_length>8 && 'quest-longest-label-slider'} ${this.state.value  === 0 ? 'slider-not-selected' : ''}`}
                min={this.state.min}
                max={this.state.max}
                step={sliderStep}
                value={this.state.value}
                orientation="horizontal"
                labels={this.state.labels}
                // format={this.formatSlider}
                tooltip={false}
                onChange={this.optionSliderChange}
                onChangeComplete={this.onChangeComplete}
            />
            );
            
        }
        return (
            <React.Fragment>
                <NumberFormat
                    disabled={selectedAss.documented}
                    onValueChange={this.ansChange}
                    allowNegative={false}
                    decimalScale={this.question.config && this.question.config.decimalPlaces ? this.question.config.decimalPlaces : 0}
                    fixedDecimalScale
                    thousandSeparator
                    value={this.question.answer.floatAns || ''}
                />
            </React.Fragment>
        );
    }
}
export default connect(store => ({
    assessments: store.assessments,
    translations: store.translations,
}))(Options);
